import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">        
        <h5>
         Privacy Policy Statement
        </h5>        
      </header>
     <body className="App-body">
       <p>
       Privacy policy outlines the privacy of our customers,clients,users and staff. This privacy policy document illustrates how we acquire,collect, manage, store, use, 
       and secure personal information and personal data that clients provide to us or that we gather in connection with any game, website, or mobile application. 
       By accessing and using our Service, you consent to the collection and use of your personal information in the manner described in this Privacy Statement. 
       </p>
       
       <p>
       This Privacy Statement outlines the ways through which we may collect and use, but we safeguard your personal information.       
       This Privacy Statement and our Terms govern our relationship with you and team members
       Any personal data we may acquire from you or that you may supply to us will be treated by us or on our behalf in accordance with the terms of these Terms of Service and any other documents referenced above. By visiting our website and utilizing our products and services,
       you agree to the terms of this Privacy Statement and the collection, use, and disclosure of your personal data as indicated in this Privacy Statement. Personal information about you will be stored in accordance with applicable data protection laws. You acknowledge that we reserve the right to modify this Privacy Statement at any time and that you should periodically visit this page to review it. 
       Any modifications to this Privacy Statement will take effect immediately upon their publication on our websites,applications and games. 
       </p>   
       <p>
       <h4>Use of Information
       </h4>
       We use information to provide, analyze, administer, enhance and personalize our services and marketing efforts, 
       to manage member referrals, to process your registration, your orders and your payments, and to communicate with you on these and other topics.
       </p>    
       <p>
       <h4>Security</h4>

       We use reasonable administrative, logical, physical and managerial measures to safeguard your personal information against loss, theft and unauthorized access, use and modification. 
       These measures are designed to provide a level of security appropriate to the risks of processing your personal information.
       </p>
     </body>
    </div>
  );
}

export default App;

